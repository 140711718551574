import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import posthog from "posthog-js";
import data from "@emoji-mart/data";
import { init } from "emoji-mart";
import { CustomEmojis } from "./components/EmojiPicker";

const { SENTRY_DSN, ENVIRONMENT } = window.ENV;

function PosthogInit() {
  useEffect(() => {
    posthog.init(window.ENV.POST_HOG_TOKEN, {
      api_host: "https://us.posthog.com",
      capture_pageview: false,
    });
  }, []);

  return null;
}

init({ data, custom: [{ id: "custom", name: "Custom Emojis", emojis: CustomEmojis }] });

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: ENVIRONMENT === "Local" ? 0 : 0.1,
  replaysOnErrorSampleRate: ENVIRONMENT === "Local" ? 0 : 1,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
      enableInp: true,
    }),
    Sentry.replayIntegration(),
  ],
  environment: ENVIRONMENT,
  release: import.meta.env.VITE_RELEASE,
  debug: false,
  // beforeSend(event) {
  //   if (import.meta.env.DEV) return null;
  //   return event;
  // },
  // beforeSendTransaction(event) {
  //   if (import.meta.env.DEV) return null;
  //   return event;
  // },
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <PosthogInit />
    </StrictMode>
  );
});
